import { useState } from 'react'
import { signIn } from 'next-auth/react'

const LoginForm = () => {
  const [email, setEmail] = useState('')

  const handleOnSubmit = e => {
    e.preventDefault()
    signIn('email', { email: email, callbackUrl: `${window.location.origin}/photos` })
  }

  return (
    <div className='flex items-center justify-center px-4 font-sans'>
      <form className='space-y-3' onSubmit={handleOnSubmit}>
        <label className='sr-only' htmlFor='email'>
          Addresse courriel
        </label>

        <input
          autoComplete='username'
          className='border-flowergray placeholder-flowergray text-flowergray relative block w-full appearance-none rounded-md border px-3 py-2 focus:z-10 focus:border-flowerGreen focus:outline-none focus:ring-flowerGreen sm:text-sm'
          id='email'
          name='email'
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          placeholder='Courriel'
          required
          type='email'
        />

        <button
          className='text-flowergray group relative flex w-full justify-center rounded-md border border-transparent bg-flowerGreen py-2 px-4 text-sm font-medium hover:bg-flowerGreen focus:outline-none focus:ring-2 focus:ring-flowerGreen focus:ring-offset-2'
          type='submit'
        >
          Se connecter avec son courriel
        </button>
      </form>
    </div>
  )
}

export default LoginForm
