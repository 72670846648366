import Image from 'next/image'
import FlowerFooter from '../../public/flower_footer.webp'

const Footer = () => {
  return (
    <div className='absolute bottom-0 -z-10 w-full text-center sm:px-32 md:px-64 lg:px-96'>
      <Image alt='Flowers' layout='responsive' src={FlowerFooter} />
    </div>
  )
}

export default Footer
