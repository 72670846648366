import { getSession } from 'next-auth/react'
import Head from 'next/head'

import AnimatedTitle from '@/components/AnimatedTitle/AnimatedTitle'

import Footer from '@/components/Footer/Footer'
import LoginForm from '@/components/LoginForm'

export default function Home () {
  return (
    <>
      <Head>
        <title>Mariage de Jeannette et Romain</title>
        <meta name='description' content='Wedding website template' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <div className='flex flex-col items-center justify-center z-10'>
        <p className='mx-auto mt-20 text-center md:text-4xl text-3xl w-10/12 md:w-8/12'>
          Le mariage de
        </p>
        <AnimatedTitle />
        <LoginForm />
      </div>

      <Footer />
    </>
  )
}

export async function getServerSideProps (context) {
  const session = await getSession(context)

  if (session) {
    return {
      redirect: {
        destination: '/photos',
        permanent: false
      }
    }
  }

  return {
    props: { session }
  }
}
